import React from 'react'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import { PageLinks } from '../common/site/page-static-links';

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const TeamDetailModule = loadable(() => import("../components/TeamDetailModule/TeamDetailModule"));
const CtaModule = loadable(() => import("../components/CtaModule/CtaModule"));
const StickyButtons = loadable(() => import("../components/StickyButtons/StickyButtons"));

const TeamDetails = ({ data }) => {
    const PageData = data?.strapiTeam
    let breadcrumData;

    if (PageLinks?.team_parent_label) {
        breadcrumData = { parentlabel: PageLinks.team_parent_label, parentlink: PageLinks.team_parent, subparentlabel: PageLinks.team_label, subparentlink: PageLinks.team, title: PageData.name }
    } else {
        breadcrumData = { parentlabel: PageLinks.team_label, parentlink: PageLinks.team, title: PageData.name }
    }

    return (
        <Layout footerCta="footercta" popularSearch={"Generic pages"}>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule {...breadcrumData} />

            <div className='layout-grey-bg-theme'>
                <TeamDetailModule {...PageData} />

                <CtaModule />
            </div>

            <StickyButtons tag="team-details" phone={PageData?.phone} teamData={PageData} />
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiTeam
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.name} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.name} />
    )
}

export default TeamDetails;

export const query = graphql`
    query ($page_id: Int) {
        strapiTeam(strapi_id: {eq: $page_id}) {
            ...TeamFragment
            slug
            offices {
                slug
                title
            }
            category {
                strapi_json_value
            }
        }
    }
`